<div class="header">
  <div class="header-button-area">
    <app-insta-chat-back-button (mousedown)="onBack($event)" color="#ffffff"/>
  </div>
  <div class="header-index-area">
    {{ currentPage() }}/{{ totalCount() }}
  </div>
</div>
<swiper-container #swiperContainer
                  init="false"
                  keyboard="true"
                  zoom="true"
                  loop="true">
  @for (image of images(); track image) {
    <swiper-slide>
      <div class="swiper-zoom-container">
        <img [src]="image"/>
      </div>
    </swiper-slide>
  }
</swiper-container>
@if(modalMode()) {
  <div class="footer">
    <button class="insta-chat-icon-button" id="click-trigger">
      <ion-icon name="download-outline"/>
    </button>
    <ion-popover mode="md"
                 trigger="click-trigger"
                 triggerAction="click"
                 side="top"
                 alignment="center"
                 class="kpop-photo">
      <ng-template>
        <div class="button-menus">
          <button class="button"
                  (click)="onDownloadAll()">{{ '@totalCount장_전체_저장@'|translate:{ totalCount: totalCount() } }}
          </button>
          <button class="button" (click)="onDownloadCurrent()">{{ '@이_사진만_저장@'|translate }}</button>
        </div>
      </ng-template>
    </ion-popover>
    <button class="insta-chat-icon-button" (click)="onShare()">
      <ion-icon name="share-social-outline"/>
    </button>
  </div>
}
