import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
  viewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IonIcon, IonPopover, ModalController } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { globalCatchError } from '@pixels/client/app-injector/global-catch-error';
import {
  InstaChatBackButtonComponent
} from '@pixels/client/components/insta-chat/design-system/buttons/insta-chat-back-button/insta-chat-back-button.component';
import { SinglePhotoViewerComponent } from '@pixels/client/components/single-photo-viewer/single-photo-viewer.component';
import { noop } from 'es-toolkit';
import { addIcons } from 'ionicons';
import { downloadOutline, shareSocialOutline } from 'ionicons/icons';
import { debounceTime, forkJoin, fromEvent, Observable, tap, timer } from 'rxjs';
import { SwiperContainer } from 'swiper/swiper-element';
import { Swiper } from 'swiper/types';


@Component({
  selector: 'app-photo-viewer-v2',
  templateUrl: './photo-viewer-v2.component.html',
  styleUrl: './photo-viewer-v2.component.scss',
  standalone: true,
  imports: [
    SinglePhotoViewerComponent,
    InstaChatBackButtonComponent,
    IonIcon,
    IonPopover,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoViewerV2Component implements OnInit {
  public readonly backed = output<MouseEvent>();
  public readonly currentUrlChange = output<string>();
  public readonly downloaded = output<string[]>();
  public readonly shared = output<string>();
  public readonly totalCount = computed(() => this.computedTotalCount());
  public readonly currentPage = computed(() => this.computedCurrentPage());
  public actionCallback: (url: string) => void = noop;
  public downloadCallback: (urls: string[]) => void = noop;
  public shareCallback: (urls: string) => void = noop;
  public images = input<string[]>([]);
  public currentUrl = input<string | undefined>();
  public modalMode = input<boolean>(false);
  private readonly swiperContainer = viewChild.required<ElementRef<SwiperContainer>>('swiperContainer');
  private readonly destroyRef = inject(DestroyRef);
  private readonly modalCtrl = inject(ModalController);

  constructor() {
    addIcons({ downloadOutline, shareSocialOutline });
  }

  private get attachSlideChange$(): Observable<CustomEvent<[swiper: Swiper]>> {
    return fromEvent<CustomEvent<[swiper: Swiper]>>(this.swiperContainer().nativeElement, 'swiperslidechange').pipe(
      debounceTime(50),
      tap(e => {
        const swiperContainer = this.swiperContainer().nativeElement;
        // 우선 loop 활성화시 activeIndex 값이 변경되는 이슈가 있어서 아래 방법으로 해결
        // 단 이 방법의 경우 마크업 변경시 영향 받음
        const targetImage = swiperContainer.querySelector('.swiper-slide-active img') as HTMLImageElement | null;
        if (targetImage) {
          this.currentUrlChange.emit(targetImage.src);
          this.actionCallback(targetImage.src);
        }
      }),
      globalCatchError()
    );
  }

  private get initSwiper(): Observable<any> {
    return timer(10).pipe(
      tap(() => {
        const swiperContainer = this.swiperContainer().nativeElement;
        swiperContainer.initialize();
        swiperContainer.swiper.slideTo(this.currentPage() - 1, 0);
      })
    );
  }

  public onBack($event: MouseEvent): void {
    $event.stopPropagation();
    if (this.modalMode()) {
      this.modalCtrl.dismiss();
    } else {
      this.backed.emit($event);
    }
  }

  public ngOnInit(): void {
    forkJoin([
      this.initSwiper,
      this.attachSlideChange$
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public onShare(): void {
    const currentUrl = this.currentUrl();
    if (currentUrl) {
      this.shared.emit(currentUrl);
      this.shareCallback(currentUrl);
    }
  }

  public onDownloadAll(): void {
    this.downloaded.emit(this.images());
    this.downloadCallback(this.images());
  }

  public onDownloadCurrent(): void {
    const currentUrl = this.currentUrl();
    if (currentUrl) {
      this.downloaded.emit([currentUrl]);
      this.downloadCallback([currentUrl]);
    }
  }

  private computedCurrentPage(): number {
    const currentUrl = this.currentUrl();
    const images = this.images();
    return images.findIndex(url => url === currentUrl) + 1;
  }

  private computedTotalCount(): number {
    return this.images().length || 1;
  }
}
